$headerFont: "Kanit", sans-serif;
$bodyFont: "Open Sans", sans-serif;

$colorPrimary: #081efc;
$colorSecondary: #1533bc;
$colorPrimaryDark: #101451;
$colorPrimaryLight: #3366ff;
$colorWhite: #f6f7f8;
$colorDark: #222636;
$colorLight: #a1a9cf;
$colorBlack: #010102;
$colorGray: #a4acce;
$colorGrayDark: darken($colorGray, 20%);
$colorGrayLight: #e6e9f6;
$colorGreen: #70ab73;
$colorRed: #d92303;
$colorOrange: #f95a0c;
$colorYellow: #eab331;
$colorPink: #ff6666;
$colorTeal: #46abee;

$textShadowColor: rgba(0, 0, 0, 0.2);
