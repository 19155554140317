@import "../../variables.scss";

.subtitles {
  position: relative;
  width: 100%;
  white-space: nowrap;
  margin: 1rem auto;

  h2 {
    font-family: $headerFont;
    font-size: 2rem;
    font-weight: 300;
    letter-spacing: 1rem;
    color: $colorWhite;
  }
}

@media only screen and (max-width: 1000px) {
  .subtitles {
    h2 {
      font-size: 1.6rem;
      letter-spacing: 1rem;
    }
  }
}
@media only screen and (max-width: 700px) {
  .subtitles {
    h2 {
      font-size: 1.4rem;
      letter-spacing: 0.6rem;
    }
  }
}
@media only screen and (max-width: 500px) {
  .subtitles {
    h2 {
      font-size: 1rem;
      letter-spacing: 0.5rem;
    }
  }
}
@media only screen and (max-width: 340px) {
  .subtitles {
    h2 {
      letter-spacing: 0.3rem;
    }
  }
}
