@import "../../variables.scss";

.home {
  position: relative;
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .home__scroll--icon {
    position: absolute;
    color: $colorGray;
    bottom: 60px;
    animation-duration: 0.6s;
    animation-name: bounceUp;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    text-shadow: 0 10px 20px $textShadowColor;
  }
}

@keyframes bounceUp {
  0% {
    color: $colorGray;
    transform: translateY(0px);
  }
  100% {
    color: $colorGrayLight;
    transform: translateY(-20px);
  }
}
