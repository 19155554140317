@import "../../variables.scss";

.wrapper {
  position: absolute;
  z-index: -1;
  height: 100%;
  width: 100%;
}

.waves__container {
  position: absolute;
  background-color: $colorPrimaryDark;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  animation: changeColors 2s;
  animation-fill-mode: forwards;

  canvas {
    display: block;
  }
}

@keyframes changeColors {
  0% {
    background-color: $colorDark;
  }
  50% {
    background-color: $colorPrimaryDark;
  }
  100% {
    background-color: $colorBlack;
  }
}
