@import "../../variables.scss";

.name {
  position: relative;
  overflow: hidden;
  padding: 0.6rem;
  background-color: $colorPrimary;
}

.name .name__text {
  padding: 2.4rem;
  background-color: $colorPrimaryLight;

  h1 {
    font-family: $headerFont;
    font-size: 8rem;
    font-weight: 900;
    color: transparent;
    text-stroke: 2px $colorWhite;
    -webkit-text-stroke: 2px $colorWhite;
    letter-spacing: 1.6rem;
    padding-left: 1.6rem;

    .word {
      cursor: default;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      .char {
        display: inline-block;
        text-align: center;
        transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      }

      .char:hover,
      .char:focus,
      .init {
        color: $colorWhite;
        transform: scale(1.5);
        text-shadow: 0 10px 20px $textShadowColor;
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .name .name__text {
    h1 {
      font-size: 6rem;
      letter-spacing: 1rem;
      padding-left: 1rem;
    }
  }
}
@media only screen and (max-width: 700px) {
  .name .name__text {
    h1 {
      font-size: 4rem;
      text-stroke: 1px $colorWhite;
      -webkit-text-stroke: 1px $colorWhite;
      letter-spacing: 0.6rem;
      padding-left: 0.6rem;
    }
  }
}
@media only screen and (max-width: 500px) {
  .name .name__text {
    padding: 1.6rem;

    h1 {
      font-size: 3rem;
    }
  }
}
@media only screen and (max-width: 340px) {
  .name .name__text {
    padding: 1rem;

    h1 {
      letter-spacing: 0.2rem;
      padding-left: 0.2rem;
    }
  }
}
