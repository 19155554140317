@import "../../variables.scss";

#about {
  position: relative;
  z-index: 2;
  min-height: 100vh;
  display: grid;
  grid-template-columns: 1fr repeat(12, minmax(auto, 60px)) 1fr;
  grid-gap: 60px;
  justify-content: center;
  align-items: center;
  margin: 120px 0;

  .about {
    grid-column: 2 / span 12;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-flow: row;
    grid-gap: 120px;
    justify-content: center;
    align-items: center;

    .about__content--container {
      grid-column-end: span 1;

      .about__header h2 {
        font-family: $headerFont;
        color: $colorDark;
        font-size: 4rem;
        font-weight: 700;
        border-bottom: 6px solid $colorYellow;
        white-space: nowrap;
        width: 120%;
      }

      .about__header hr {
        border: 3px solid $colorGrayLight;
        margin: 1rem auto;
      }

      .about__content p {
        color: $colorDark;
        font-family: $bodyFont;
        font-weight: 300;
        font-size: 2rem;
        line-height: 45px;
        text-align: left;
        margin-bottom: 1rem;

        a {
          text-decoration: none;
          color: $colorDark;
          background-image: linear-gradient(
            120deg,
            $colorYellow 0%,
            $colorYellow 100%
          );
          background-repeat: no-repeat;
          background-size: 100% 0.2em;
          background-position: 0 88%;
          transition: background-size 0.25s ease-in;

          &.external__link {
            background-image: linear-gradient(
              120deg,
              $colorTeal 0%,
              $colorTeal 100%
            );
          }

          &:hover {
            background-size: 100% 88%;
          }
        }

        img.flag {
          height: 2.8rem;
          margin-left: 0.2rem;
          margin-right: 0;
          width: auto;
          vertical-align: bottom;
        }
      }
    }

    .about__image--background {
      position: absolute;
      z-index: -1;
      background-color: $colorGrayLight;
      height: 50%;
      width: 1500px;
      transform: translate(30%, 0%) rotate(10deg);
    }

    .about__image--container {
      margin: auto;
      grid-column-end: span 1;
      background-color: $colorPrimaryLight;
      transform-style: preserve-3d;
      transform: perspective(1000px);
      max-width: 500px;

      .about__image {
        max-width: 500px;
        width: 100%;
        height: auto;
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
        transition: all 0.3s ease-in-out;
        transform: translateX(-20px) translateY(20px) translateZ(20px);
        padding: 0;
      }

      &:hover,
      &:focus {
        background-color: $colorSecondary;
        .about__image {
          box-shadow: 0 10px 20px $textShadowColor;
          transform: translateX(20px) translateY(-20px) translateZ(20px);
        }
      }
    }
  }
}

@media only screen and (max-width: 1253px) {
  #about {
    .about {
      .about__content--container {
        .about__header h2 {
          font-size: 4rem;
        }

        .about__content p {
          line-height: 40px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  #about {
    margin: 60px 0;
    .about {
      grid-gap: 60px;
      .about__content--container {
        .about__header h2 {
          font-size: 2.6rem;
        }

        .about__content p {
          font-size: 1.25rem;
          line-height: normal;
        }
      }

      .about__image--container {
        max-width: 300px;

        .about__image {
          max-width: 300px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  #about {
    .about {
      .about__content--container {
        .about__content p {
          line-height: normal;

          img.flag {
            height: 2rem;
            margin: 0 0.1rem;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 885px) {
  #about {
    .about {
      .about__content--container {
        grid-column-end: span 1;

        .about__header h2 {
          font-size: 2.2rem;
        }

        .about__content p {
          font-size: 1.2rem;
          line-height: normal;
        }
      }

      .about__image--background {
        height: 50%;
        transform: translate(15%, 50%) rotate(10deg);
      }

      .about__image--container {
        grid-column-end: span 1;

        .about__image {
          padding: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 700px) {
  #about {
    grid-gap: 20px;
    margin: 20px 0px;

    .about {
      .about__content--container {
        grid-column-end: span 2;
        .about__header h2 {
          font-size: 2.6rem;
          width: auto;
          white-space: normal;
        }

        .about__content p {
          font-size: 1.25rem;
          line-height: normal;
        }
      }

      .about__image--background {
        height: 50%;
        transform: translate(-5%, 20%) rotate(10deg);
      }

      .about__image--container {
        justify-self: center;
        grid-column-end: span 2;
        max-width: 200px;
      }
    }
  }
}

@media only screen and (max-width: 350px) {
  #about {
    .about {
      .about__content--container {
        .about__header h2 {
          font-size: 2.2rem;
        }

        .about__content p {
          font-size: 1rem;
        }
      }
    }
  }
}
