@import "../../variables.scss";

#contact {
  min-height: 100vh;
  max-width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $colorWhite;

  .contact {
    .contact__header {
      text-align: center;

      * {
        margin: 2rem auto;
      }

      h3 {
        font-size: 2.6rem;
        font-family: $headerFont;
        color: $colorDark;
      }

      hr {
        border: 0.2rem $colorPrimary solid;
        background-color: $colorPrimary;
        width: 10rem;
      }

      p {
        font-family: $bodyFont;
        color: $colorDark;
        font-size: 1.4rem;
        font-weight: 300;
        padding: 0 2rem;
      }
    }

    .contact__body {
      display: inline-flex;
      width: 100%;
      text-align: center;
      align-items: center;

      .contact__body--parallax-item {
        width: 100%;
        margin: 2rem;
        padding: 2rem 6rem;
        font-family: $headerFont;
        font-size: 1.4rem;
        color: $colorDark;
        background-color: $colorGrayLight;
        transform-style: preserve-3d;
        white-space: nowrap;
        transition: background-color 0.2s ease-in-out;

        &:hover {
          z-index: 999;
          background-color: $colorGray;
          box-shadow: 0 20px 40px $textShadowColor;
        }

        p,
        svg {
          margin: 0.4rem;
        }

        svg {
          color: $colorPrimary;
        }

        .inner-parallax {
          transform: translateZ(80px);
        }
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  #contact {
    .contact {
      .contact__header {
        margin: 1.2rem;

        p {
          margin: 2rem;
        }
      }
      .contact__body {
        margin: 1.2rem;

        .contact__body--parallax-item {
          padding: 1rem 2rem;
          font-size: 1.2rem;
        }
      }
    }
  }
}

@media only screen and (max-width: 700px) {
  #contact {
    .contact {
      .contact__header {
        margin: 2rem;

        p {
          margin: 0;
          padding: 0;
        }
      }

      .contact__body {
        display: flex;
        flex-direction: column;
        width: auto;
        margin: 2rem;

        .contact__body--parallax-item {
          margin: 0.2rem auto;
        }
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  #contact {
    .contact {
      .contact__header {
        margin: 0 2rem;
        padding: 0;

        * {
          margin: 0;
          padding: 0;
        }

        p {
          margin: 0.4rem;
          font-size: 1.2rem;
        }

        h3 {
          margin: 0.4rem;
          font-size: 2rem;
        }

        hr {
          margin: 1rem auto;
        }
      }

      .contact__body {
        margin: 0 2rem;

        .contact__body--parallax-item {
          padding: 0.6rem 1rem;
        }
      }
    }
  }
}
