@import "./variables.scss";

html,
body {
  max-width: 100vw;
  overflow-x: clip;
  overflow-y: auto;
}

body {
  background-color: $colorWhite;
}

.app__loading {
  height: 100vh;
}
